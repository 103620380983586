<template>
  <div
    class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            User Plan Subscription
          </h3>
        </div>
        <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right"
        >
          <!-- <button
            class="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
          >
            See all
          </button> -->
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead class="thead-light">
          <tr>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              User
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Plan
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >
              Cluster(s)
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"
            >
              Amount
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"
            >
              Status
            </th>
            <!-- <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"
            >
              Profit/Loss
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"
            >
              Rate
            </th> -->
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"
            >
              Summary
            </th>
            <th
              class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px"
            >
              Date
            </th>
          </tr>
        </thead>
        <tbody v-if="has('user.plan.view')">
          <template v-if="!loaders().fetchPlanUser">
            <tr v-for="(userPlan, i) in plans.data" :key="i" class="cursor-pointer" @mouseenter="userPlanIdx = i">
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
                :class="{
                  'text-red-500': !userPlan.user?.settings?.is_verified
                }"
              >
                <!-- {{ userPlan.user?.name }} 
                <transition name="bounce"><i v-if="userPlanIdx === i" class="fa fa-exclamation-circle text-orange-500"></i></transition><br/>  -->
                <div class="flex">
                  {{ userPlan.user?.name }} 
                  <!-- <transition name="bounce"><i v-if="userPlanIdx === i" class="text-gray-500">show</i></transition><br/>  -->
                  <review-dropdown :reviews="userPlan.user?.reviews" model="user" :model_id="userPlan.user?.id"></review-dropdown>
                </div>
                <small class="text-blueGray-400">{{ userPlan.user?.email }}</small>
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                @click="showUserPlanModal(userPlan)"
              >
                {{ userPlan.plan?.name }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                @click="showUserPlanModal(userPlan)"
              >
                <template v-if="(userPlan.user?.clusters || []).length > 0">
                  <span v-for="(cluster, j) in (userPlan.user?.clusters || [])" class="text-blueGray-400" :class="{
                    'text-emerald-600': cluster.status === 'APPROVED',
                    'text-blueGray-700': cluster.status === 'PENDING',
                    'text-orange-500': cluster.status === 'PARTIALLY_BLOCKED'
                  }" :key="j">
                    {{ cluster.name }} (<i>{{ cluster.code || 'nill' }}</i>) 
                    <!-- <i v-if="userPlanIdx === i" class="fa fa-times text-red-500 cursor-pointer" @click="handleRemoveFromCluster(userPlan, cluster)" aria-hidden="true"></i>
                    <br/> -->
                  </span>
                </template>
                <span v-else class="text-red-500">
                  <i>no cluster</i>
                </span>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <small class="text-xs">{{ formatter(userPlan.currency).format(userPlan.amount) }}</small>
                  <!-- <span class="px-2 py-1 bg-warmGray-400 rounded-lg" v-for="(tag, j) in JSON.parse(plan.risk_type)" :key="j">{{ tag }}</span> -->
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 cursor-pointer"

              >
                {{ userPlan?.status }} 
                <!-- <transition name="bounce"><i v-if="userPlanIdx === i" class="fa fa-pen-square text-orange-500"></i></transition> -->
              </td>
              <!-- <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <small class="text-xs">{{ userPlan.currency }}</small> &nbsp;{{ Math.round(getPlanBalance(userPlan) * 10) / 10 }} 
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ userPlan.rate }}
              </td> -->
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 overflow-hidden"
              >
                <div v-if="userPlan.type !== 'crypto'">
                  <div v-if="['PENDING', 'INITIATED', 'APPROVED'].includes(userPlan?.status)">
                    <button
                      class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      @click.self="toggleConfirmPlanModal(userPlan)"
                      v-if="has('loan.confirm') && userPlan?.status === 'APPROVED'"
                    >
                      disburse
                    </button>
                    <button
                      class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      @click.self="toggleConfirmPlanModal(userPlan)"
                      v-else-if="userPlan?.status === 'INITIATED' || userPlan?.status === 'PENDING'"
                    >
                      approve
                    </button>

                    <button
                      class="bg-red-500 text-white active:bg-red-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      @click.stop="toggleDeclinePlanModal(userPlan)"
                      v-if="has('plan.terminate.ability') || has('plan.terminate')"
                    >
                      decline
                    </button>
                  </div>
                  <div 
                  v-else-if="['ACTIVE', 'COMPLETED', 'RETRY', 'DUE', 'DEFAULT'].includes(userPlan?.status)" class="flex flex-inline"

                  >
                    <!-- <transition name="bounce"><i v-if="userPlanIdx === i" class="fa fa-pen-square text-orange-500"></i></transition> -->
                    <template v-if="userPlan.loans && userPlan.loans.length > 0">
                      <span 
                      v-for="(loan, i) in userPlan.loans" :key="i" 
                      class="w-5 h-5 rounded-full border-2 border-blueGray-50 shadow" 
                      :class="`${loan?.status === 'PAID' ? 'bg-emerald-600' : 'bg-red-600'}`"
                      :title="loan.status"
                      ></span>
                    </template>
                    <template v-else-if="userPlan.savings && userPlan.savings.length > 0">
                      <span 
                      v-for="(save, i) in userPlan.savings" 
                      :key="i" 
                      class="w-5 h-5 rounded-full border-2 border-blueGray-50 shadow" 
                      :class="{
                        'bg-emerald-600': save.status === 'PAID',
                        'bg-orange-500': save.status === 'WITHDRAWN',
                        'bg-red-600': !['PAID', 'WITHDRAWN'].includes(save.status)
                      }"
                      :title="loan?.status || 'none'"
                      ></span>
                    </template>
                    <template v-else-if="userPlan.investments && userPlan.investments.length > 0">
                      <span 
                      v-for="(investment, i) in userPlan.investments" 
                      :key="i" 
                      class="w-5 h-5 rounded-full border-2 border-blueGray-50 shadow" 
                      :class="`${investment.status === 'PAID' ? 'bg-emerald-600' : 'bg-red-600'}`"
                      :title="loan?.status || 'none'"
                      ></span>
                    </template>
                    <template v-else>
                      <i>no payment plan</i>
                    </template>
                  </div>
                </div>
                <div v-else class="flex overflow-hidden">
                  <img
                    :src="coin.purchased_coin.logo"
                    :alt="coin.purchased_coin.symbol"
                    class="w-5 h-5 rounded-full border-2 border-blueGray-50 shadow" 
                    v-for="(coin, i) in userPlan.user_purchased_coins"
                    :key="i"
                  />
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ moment(userPlan.created_at).format("DD MMM YY") }}
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="6" rowspan="6">
                <div class="flex items-center justify-center w-full my-6">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
            nothing to view yet
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <!-- {{ plans }} -->
        <circular-pagination :current_page="plans.current_page" :last_page="plans.last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
      </div>
    </div>

    <div v-if="showConfirmModal" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
             {{ plan?.user?.name }} {{ plan.name }} {{ plan.type }} 
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleConfirmPlanModal()"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <p class="px-4">
              Kindly confirm you want to {{ {"INITIATED": "approve", "PENDING": "approve", "APPROVED": "disburse"}[plan.status] }} this loan
            </p>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
              type="button" 
              v-on:click="toggleConfirmPlanModal()"
            >
              Close
            </button>

            <button 
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
              type="button" 
              :disabled="loaders().confirmLoan"
              @click="approvePlan()"
              v-if="plan.status==='INITIATED' || plan.status ==='PENDING'"
            >
              Approve
            </button>

            <!-- <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
              type="button" 
              :disabled="loaders().confirmLoan"
              @click="confirmPlan()"
              v-if="plan.status==='APPROVED'"
            >
              Confirm and Disburse
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="showConfirmModal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>

    <div v-if="showDeclineModal" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto max-w-sm">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              {{ plan?.user?.name }} {{ plan.name }} {{ plan.type }} 
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleDeclinePlanModal()"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <p class="px-4">
              Kindly confirm you want to decline this loan
            </p>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleDeclinePlanModal()"
            >
              Close
            </button>
            <button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
            type="button" 
            :disabled="loaders().updatePlanUser"
            @click="declinePlan()">
              Decline and Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showDeclineModal" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
    
    <user-plan-modal :userPlan="plan" :show="modals.userPlan" @close="() => modals.userPlan = false"></user-plan-modal>
  </div>
</template>

<script>
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import UserPlanModal from "@/components/Modals/UserPlanModal.vue";
import { mapActions, mapGetters } from 'vuex';
import { formatter } from "../../utils/formatter.util";
import moment from "moment"
import ReviewDropdown from "@/components/Dropdowns/UserReviewDropdown.vue";

export default {
  components: {
    CircularPagination,
    UserPlanModal,
    ReviewDropdown
  },
  props: ["plans", "param"],
  created() {
    this.moment = moment
    this.formatter = formatter
  },
  data() {
    return {
      showConfirmModal: false,
      showDeclineModal: false,
      plan: {},
      pagination: {},
      modals: {},
      userPlanIdx: null
    }
  },
  methods: {
    ...mapActions(["fetchPlanUser", "confirmLoan", "updatePlanUser", "leaveBusiness"]),
    ...mapGetters(["loaders"]),
    selectPlan(plan) {
      if (this.has("plans.show")) {
        this.$emit('selectedPlan', plan)
      }
    },
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewPageUser();
    },
    getPlanBalance(plan) {
        return plan.user_purchased_coins.reduce( (t, coin) => t + ( coin.price_sold ? Math.round((parseFloat(coin.price_sold) - parseFloat(coin.price_bought)) * 10)/10 : 0), 0 )
    },
    getPlanSpent(plan) {
        return plan.user_purchased_coins.reduce( (t, coin) => t + ( coin.price_sold ? Math.round(parseFloat(coin.price_bought) * 10)/10 : 0), 0 )
    },
    fetchNewPageUser() {
      const params = {
        ...this.pagination,
        ...this.param
      }
      this.fetchPlanUser(params)
    },
    confirmPlan() {
      this.confirmLoan(this.plan).then( () => {
        this.plan.status = "ACTIVE"
        this.toggleConfirmPlanModal()
      })
    },
    approvePlan() {
      const initialStatus = this.plan.status;
      this.plan.status = "APPROVED"
      const data = this.plan;
      this.updatePlanUser(data).then(() => {})
      .catch( () => this.plan.status = initialStatus)
    },
    declinePlan() {
      const initialStatus = this.plan.status;
      this.plan.status = "DECLINED"
      const data = this.plan;
      this.updatePlanUser(data).then(() => {
        this.toggleDeclinePlanModal()
      }).catch( () => this.plan.status = initialStatus)
    },
    toggleConfirmPlanModal: function(plan = null){
      this.plan = plan === null ? {} : plan;
      this.showConfirmModal = !this.showConfirmModal;
    },
    toggleDeclinePlanModal: function(plan = null){
      this.plan = plan === null ? {} : plan;
      this.showDeclineModal = !this.showDeclineModal;
    },
    showUserPlanModal: function(plan = null){
      this.plan = plan === null ? {} : plan;
      this.modals.userPlan = true;
    },
    async handleRemoveFromCluster(userPlan, cluster) {
      if (!confirm("Are you sure you want to remove this user from this cluster?")){
        return
      }
      await this.leaveBusiness({userPlan: userPlan, code: cluster.code})
    }
  }
}
</script>

<style scoped>
.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>