<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full">
      <div class="relative flex flex-col min-w-0 break-words  w-full shadow-lg rounded">
        <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(1)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 1, 'text-white bg-orange-500': openTab === 1}">
              Transactions
            </a>
          </li>
          <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(2)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 2, 'text-white bg-orange-500': openTab === 2}">
              Payment Logs
            </a>
          </li>
          <!-- <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleTabs(3)" v-bind:class="{'text-emerald-600 bg-white': openTab !== 3, 'text-white bg-orange-500': openTab === 3}">
              Provider Logs
            </a>
          </li> -->
        </ul>
      </div>
      <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <div v-bind:class="{'hidden': openTab !== 1, 'block': openTab === 1}" class="flex flex-wrap">
              <div class="w-full px-4">
                <card-transaction />
              </div>
            </div>
            <div v-bind:class="{'hidden': openTab !== 2, 'block': openTab === 2}" class="flex flex-wrap">
              <div class="w-full px-4">
                <card-payment-log />
              </div>
            </div>
            <div v-bind:class="{'hidden': openTab !== 3, 'block': openTab === 3}" class="flex flex-wrap">
              <div class="w-full px-4">
                <card-provider-log />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="w-full mb-12 px-4">
      <card-transaction color="dark" />
    </div> -->
  </div>
</template>
<script>
import CardTransaction from "@/components/Cards/CardTransaction.vue";
import CardPaymentLog from "@/components/Cards/CardPaymentLog.vue";
import CardProviderLog from "@/components/Cards/CardProviderLog.vue";

export default {
  components: {
    CardTransaction,
    CardPaymentLog,
    CardProviderLog,
  },
  data() {
    return {
      openTab: 1
    }
  },
  methods: {
    toggleTabs: function(tabNumber){
      this.openTab = tabNumber
    }
  }
};
</script>
