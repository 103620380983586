import http from "./http";

export const GetProfile = async() => {
    return await http.get("/profile");
};

export const SaveProfile = async data => {
    return await http.post("/profile", data)
}

export const GetAuthUser = async() => {
    return await http.get("/auth/me");
};

export const Logout = async() => {
    return await http.get("/auth/logout");
};

export const AuthUser = async(data) => {
    return await http.post("admin/auth/login", data);
}

export const RegisterUser = async(data) => {
    return await http.post("/auth/register", data);
}

export const Verify = async(data) => {
    return await http.post("/auth/verify", data);
}

export const AuthCode = async(data) => {
    return await http.post("/auth/code", data);
}

export const ValidateCode = async(data) => {
    return await http.post("/auth/code/validate", data);
}

export const ForgotPassword = async(data) => {
    return await http.post("/forgot/password", data);
}

export const ResetPassword = async(data) => {
    return await http.post("/reset/password", data);
}

export const ChangePassword = async(data) => {
    return await http.post("/user/change-password", data);
}

export const GetAllUsers = async(params) => {
    let data = {
        params
    }
    if ( params && params.get_csv) {
        data.responseType = 'blob'
    }

    return await http.get("/v1/users", data);
};

export const BlockUser = async user => {
    return await http.get(`/user/${user}/block`);
}

export const UnBlockUser = async user => {
    return await http.get(`/user/${user}/unblock`);
}

export const GetNextOfKin = async() => {
    return await http.get("/next-of-kin");
};

export const SaveNextOfKin = async data => {
    return await http.post("/next-of-kin", data)
}

export const UpdateNextOfKin = async (id, data) => {
    return await http.put(`/next-of-kin/${id}`, data)
}

export const DeleteNextOfKin = async id => {
    return await http.delete(`/next-of-kin/${id}`)
}

export const TransactionPinReset = async (data) => {
    return await http.patch("/wallet/pin-reset", data)
}