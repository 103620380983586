<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
        <card-line-chart :data="transactionData" title="Sales Record" />
      </div>
      <div class="w-full xl:w-4/12 px-4">
        <card-bar-chart :data="userData" title="User Registration" />
      </div>
    </div>

    <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
        <card-transactions @transactionChange="loadDashboard"></card-transactions>
      </div>
      <!-- <div class="w-full xl:w-4/12 px-4">
        <card-prediction-summary title="Best Prediction" link=coins" :data="predictions().first_match.map( f => ({description: f.symbol, value: f.price, change: f.percent_change_1h, logo: f.logo}))" />
      </div> -->
    </div>
  </div>
</template>
<script>
import CardLineChart from "@/components/Cards/CardLineChart.vue";
import CardBarChart from "@/components/Cards/CardBarChart.vue";
import CardTransactions from "@/components/Cards/CardTransactions.vue";
// import CardPredictionSummary from "@/components/Cards/CardPredictionSummary.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "dashboard-page",
  components: {
    CardLineChart,
    CardBarChart,
    CardTransactions,
    // CardPredictionSummary,
  },
  data() {
    return {
      userData: {},
      transactionData: {}
    }
  },
  mounted(){
    this.getAllPredictions();
    this.loadDashboard({})
  },
  methods: {
    ...mapActions(["getDashboard", "getAllPredictions", "authUser"]),
    ...mapGetters(["dashboard", "predictions"]),
    async loadDashboard(params = {}) {
      try {
        // Call the authUser action to ensure user data is fetched
        const authUserData = await this.authUser();

        // Log the authenticated user data
        console.log("Authenticated User Data:", authUserData);

        const res = await this.getDashboard(params);
        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const registeredUser = [];
        const unverifiedUser = [];
        const creditRecord = [];
        const debitRecord = [];

        for (let i = 0; i < days.length; i++) {
          const userRecord = res.users[i];

          if (userRecord) {
            registeredUser.push(userRecord.register);
            unverifiedUser.push(userRecord.unverified);
          } else {
            registeredUser.push(0);
            unverifiedUser.push(0);
          }

          const transactionRecord = res.transactions[i];

          if (transactionRecord) {
            creditRecord.push(transactionRecord.credit);
            debitRecord.push(transactionRecord.debit);
          } else {
            creditRecord.push(0);
            debitRecord.push(0);
          }
        }

        this.userData = {
          labels: days,
          datasets: [
            {
              label: "Unverified",
              backgroundColor: "#ed64a6",
              borderColor: "#ed64a6",
              data: unverifiedUser,
              fill: false,
              barThickness: 8,
            },
            {
              label: "Registered",
              fill: false,
              backgroundColor: "#4c51bf",
              borderColor: "#4c51bf",
              data: registeredUser,
              barThickness: 8,
            },
          ],
        };

        this.transactionData = {
          labels: days,
          datasets: [
            {
              label: "Credit",
              backgroundColor: "#4c51bf",
              borderColor: "#4c51bf",
              data: creditRecord,
              fill: false,
            },
            {
              label: "Debit",
              fill: false,
              backgroundColor: "#fff",
              borderColor: "#fff",
              data: debitRecord,
            },
          ],
        };
      } catch (error) {
        console.error("Error loading dashboard:", error);
      }
    }
  }
};
</script>
