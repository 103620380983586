<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
    :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
  >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            class="font-semibold text-lg"
            :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
          >
            Transactions
          </h3>
        </div>

        <div class="flex w-full flex-wrap">
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3 text-xs">
              page size
              <select :disabled="!has('transaction.view') || loading || loaders().fetchTransactions" @change="fetchNewUserPage" v-model="pageSize" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150">
                <option v-for='(cur, i) in [10, 15, 20, 50, 10]' :key="i" >{{ cur }}</option>
              </select>
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3 text-xs">
              type
              <select 
                :disabled="!has('transaction.view') || loading || loaders().fetchTransactions" 
                @change="fetchNewUserPage" 
                v-model="category" 
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
              >
                <option v-for='(cur, i) in transactionList' :key="i" >{{ cur }}</option>
              </select>
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3 text-xs">
              search
              <input 
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                :disabled="!has('transaction.view') || loading || loaders().fetchTransactions" 
                @keyup="searchTransaction" 
                v-model="search"
              />
            </div>
          </div>


          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3 text-xs">
              start date
              <input 
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                id="start-date-range-transaction"
                type="text"
                placeholder="Start Date"
              />
            </div>
          </div>
          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3 text-xs">
              end date
              <input 
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150" 
                id="end-date-range-transaction"
                type="text"
                placeholder="End Date"
              />
            </div>
          </div>

          <div class="w-full lg:w-3/12 px-4">
            <div class="relative w-full mb-3 text-xs">
              Download CSV
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 max-w-full"
                type="button" 
                @click="() => { get_csv=true; fetchNewUserPage()}"
                :disabled="!has('transaction.view') || loading || loaders().fetchTransactions" 
              >
                Download
              </button>
            </div>
          </div>


        </div>

      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Name
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Type
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Amount
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Balance <br/>
              <small>(Before / After)</small>
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Status
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
              Charges
            </th>
            
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Date
            </th>
            <th
              class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
              :class="[
                color === 'light'
                  ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                  : 'bg-emerald-800 text-emerald-300 border-emerald-700',
              ]"
            >
            Details
            </th>
          </tr>
        </thead>
        <tbody v-if="has('transaction.view')">
          <template v-if="!loaders().fetchTransactions">
            <tr 
              v-for="(trn, i) in transactions().data" 
              :key="i"
            >
              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                <span>
                  {{ trn.user?.name }} <br/>
                  <small>{{ trn.user?.email }}</small>
                </span>
              </th>

              <th
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
              >
                <span>
                  {{ trn.category }} 
                  <small v-if="['CREDIT', 'DEBIT'].includes(trn.type)"
                  :class="[
                    trn.type === 'CREDIT' ? 'text-emerald-600' : '',
                    trn.type === 'DEBIT' ? 'text-orange-500' : '',
                    trn.type === 'NONE' ? 'text-blueGray-600' : '',
                  ]"
                  >({{ {"CREDIT": "CR", "DEBIT": "DR"}[trn.type] }})</small>
                </span>
              </th>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <i :class="`fas mr-1 ${trn.type === 'CREDIT' ? 'fa-arrow-up text-emerald-500' : 'fa-arrow-down text-red-500'}`"></i>
                {{ formatter(trn.currency).format(trn.gross_amount) }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                {{ formatter(trn.currency).format(JSON.parse(trn.meta || "{}").balance_before || 0) }} / 
                {{ formatter(trn.currency).format(JSON.parse(trn.meta || "{}").balance || 0) }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                :class="[
                  trn.status === 'SUCCESSFUL' ? 'text-emerald-600' : '',
                  trn.status === 'FAILED' ? 'text-orange-500' : '',
                ]"
              >
                <!-- <i class="fas fa-circle text-orange-500 mr-2"></i> --> {{ trn.status }}
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">{{ trn.charge }}</span>
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              >
                <div class="flex items-center">
                  <span class="mr-2">{{ moment(trn.created_at).format("DD MMM YY hh:mm") }}</span>
                </div>
              </td>
              <td
                class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
                @click="transaction = {
                  id: trn.id,
                  reference: trn.reference,
                  desc: trn.description, 
                  user_created_at: trn.user?.created_at,
                  ...(JSON.parse(trn.meta) || {})
                }; confirmReversal = false; toggleModal('transaction', true)"
              >
                <!-- <trans-dropdown :data="{}" /> -->
                <span class="text-blueGray-500 py-1 px-3"><i class="fas fa-ellipsis-v"></i></span>
              </td>
            </tr>
            <!-- 
            <tr>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center"
            >
              <img
                :src="react"
                class="h-12 w-12 bg-white rounded-full border"
                alt="..."
              />
              <span
                class="ml-3 font-bold"
                :class="[
                  color === 'light' ? 'text-blueGray-600' : 'text-white',
                ]"
              >
                React Material Dashboard
              </span>
            </th>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              $4,400 USD
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <i class="fas fa-circle text-teal-500 mr-2"></i> on schedule
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex">
                <img
                  :src="team1"
                  alt="..."
                  class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow"
                />
                <img
                  :src="team2"
                  alt="..."
                  class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                />
                <img
                  :src="team3"
                  alt="..."
                  class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                />
                <img
                  :src="team4"
                  alt="..."
                  class="w-10 h-10 rounded-full border-2 border-blueGray-50 shadow -ml-4"
                />
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <div class="flex items-center">
                <span class="mr-2">90%</span>
                <div class="relative w-full">
                  <div
                    class="overflow-hidden h-2 text-xs flex rounded bg-teal-200"
                  >
                    <div
                      style="width: 90%;"
                      class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"
                    ></div>
                  </div>
                </div>
              </div>
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right"
            >
              <table-dropdown />
            </td>
          </tr>
           -->
          </template>
          <template v-else>
            <tr>
              <td colspan="4" rowspan="4">
                <div class="flex items-center justify-center w-full my-6">
                  <span class="loader block"></span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <th
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
            >
              no permission to view transaction
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex flex-wrap items-center">
      <div class="relative w-full px-4 max-w-full flex-grow flex-1">
        <circular-pagination :current_page="transactions().current_page" :last_page="transactions().last_page" :size="pageSize" @paginate="pageChange" ></circular-pagination>
      </div>
    </div>


    <div v-if="modals.transaction" style="width: 100%;" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-screen h-screen top-0 left-0">
      <div class="relative w-auto my-6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <h3 class="text-xl font-semibold">
              Transaction
            </h3>
            <button 
              class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" 
              v-on:click="toggleModal('transaction', false)"
            >
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <recursive-table :data="transaction" :exclude="['id']" />
            <div v-if="confirmReversal">
              <hr/>
              <div class="flex flex-wrap">
                <div class="w-8/12 px-4">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Enter transaction reference
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none   w-full ease-linear transition-all duration-150"
                      v-model="confirmReference"
                    />
                  </div>
                </div>

                <div class="w-4/12 mb-3 px-4 mt-8">
                  <button
                    class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 w-full"
                    type="button" 
                    @click="reverseTransaction" 
                    :disabled="loaders().reverse || !confirmReference"
                    v-if="has('wallet.debit.ability')"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--footer reverseTransaction-->
          <div class="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b">
            <button
              class="text-emerald-600 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"

            >

            </button>

            <button 
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" 
              v-on:click="toggleModal('transaction', false)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modals.transaction" style="width: 100%; opacity: 0.25;" class="opacity-25 fixed inset-0 z-40 h-screen top-0 left-0 bg-black"></div>
  </div>
</template>
<script>
// import TransDropdown from "@/components/Dropdowns/TransDropdown.vue";
import CircularPagination from "@/components/Paginations/CircularPagination.vue";
import { transactionList, formatter } from '../../utils'
import bootstrap from "@/assets/img/bootstrap.jpg";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { createToaster } from "@meforma/vue-toaster";
import moment from "moment"
import RecursiveTable from '@/components/Tables/RecursiveTable.vue';
const toaster = createToaster({ /* options */ });
import datepicker from 'js-datepicker'

export default {
  data() {
    return {
      bootstrap,
      pageSize: 10,
      category: "",
      search: "",
      timer: null,
      loading: false,
      get_csv: false,
      pagination: {},
      transactionList,
      modals: {},
      transaction: {},
      confirmReversal: false,
      confirmReference: "",
      from: null,
      to: null
    };
  },
  components: {
    // TransDropdown,
    RecursiveTable,
    CircularPagination,
  },
  props: {
    color: {
      default: "light",
      validator: function (value) {
        // The value must match one of these strings
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    ...mapActions(["getTransactions", "reverse"]),
    ...mapGetters(["transactions", "loaders"]),
    ...mapMutations(["updateTransactionQueryParams"]),
    pageChange(pagination) {
      this.pagination = pagination;
      this.fetchNewUserPage();
    },
    fetchNewUserPage() {
      const params = {
        ...this.pagination,
        get_csv: this.get_csv,
        search: this.search,
        category: this.category,
        from: this.from,
        to: this.to
      }
      if ( !params.get_csv ) {
        this.updateTransactionQueryParams(params)
        this.$emit('transactionChange', params)
      }
      const self = this
      this.loading = true

      this.getTransactions(params).then( response => {
        if ( self.get_csv ) {
          // create file link in browser's memory
          const href = window.URL.createObjectURL(response);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download','transactions.csv'); //or any other extension
          document.body.appendChild(link);
          link.click();
          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          toaster.success("Report downloaded Successfully");
        }
      }).finally(() => {
        self.get_csv = false
        self.loading = false
      })
    },
    searchTransaction() {
      const self = this
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        self.fetchNewUserPage()
      }, 1000)
    },
    toggleModal(modalName, isvisible) {
      this.modals[modalName] = isvisible
    },
    async reverseTransaction() {
      const transaction = this.transaction;
      if (!this.confirmReversal || transaction.reference !== this.confirmReference) {
        toaster.error("Please confirm the reversal of this transaction")
        return
      }
      await this.reverse(transaction)
    }
  },
  mounted() {
    const params = {}
    const {search} = this.$route.query;
    if ( search ) {
      params.search = search
    }
    this.getTransactions(params)

    const self = this
    setTimeout( function() {
      self.start_date = datepicker("#start-date-range-transaction", {
        id: "transaction-card-range",
        onSelect: (_, date) => {
          self.from = date;
          self.searchTransaction()
        },
        // disabler: date => moment(new Date).isBefore(moment(date))
      });

      self.end_date = datepicker("#end-date-range-transaction", {
        id: "transaction-card-range",
        onSelect: (_, date) => {
          self.to = date;
          self.searchTransaction()
        },
        // disabler: date => moment(new Date).isBefore(moment(date))
      })
    }, 1000)
  },
  created() {
    this.moment = moment
    this.formatter = formatter
  }
};
</script>
